import Bancumbre from '../assets/bancumbre.svg'
import Pipol from '../assets/pipol.svg'
import Zelle from '../assets/zelle.svg'
import Venmo from '../assets/venmo.svg'
import Reserve from '../assets/reserve.svg'
import Card from '../assets/card.svg'
import Binance from '../assets/binance.svg'
import Pm from '../assets/pm.svg'
import Paypal from '../assets/paypal.svg'
import axios from 'axios'

/** 
 * images object to be used on getPayMethods func
*/
let images = {
    'bancumbre': Bancumbre,
    'pipolPay': Pipol,
    'zelle': Zelle,
    'reserve': Reserve,
    'card': Card,
    'pagoMovil': Pm,
    'venmo': Venmo,
}

/**
 * function that return used pay methods
 * @param {string} service 
 * @returns {string[]}
 */
export const getPayMethods = async (service) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_PAYMENTS_URL}/transactions/info/`, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": `${process.env.REACT_APP_API_KEY_PAYMENTS}`
            }
        })
        // console.log(res)
        let data = [
            {
                name: 'Tarjeta',
                img: Card,
            },
            // {
            //     name: 'PayPal',
            //     img: Paypal,
            // },
            {
                name: 'Binance',
                img: Binance,
            }
        ]
        const serviceName = service === "Yummy Delivery" ? 'deliveryInfo' : 'ridesInfo'
        // selecting the service type 
        const payMethods = res.data.paymentInfo[serviceName]
        for(let method of usedMethods){
            if(payMethods[method]){
                const nameCapitalized = method.charAt(0).toUpperCase() + method.slice(1)
                const isMethodName = method === "bancumbre" || method === "pagoMovil"
                let methodInfo = {
                    name: nameCapitalized, 
                    img: images[method],
                    data: {}
                }

                isMethodName
                ? methodInfo.data = payMethods[method]
                : methodInfo.data = { account: payMethods[method] }

                data.push(methodInfo)
            }
        }
        // console.log('asdsdfsdfs', data)
        return data

    }catch(e){

    }
}

/** 
 * methods that are useing by rechage webapp only 
 */
const usedMethods = [
    "pagoMovil",
    "zelle",
    "reserve",
    "pipolPay",
]
import axios from 'axios'

export const getDolarRate = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_PAYMENTS_URL}/transactions/rate/`, {
            headers: {
                "Content-Type": "application/json",
                "x-api-key": `${process.env.REACT_APP_API_KEY_PAYMENTS}`
            }
        })
        return res
    }catch(e){
        console.log(e)
    }
}
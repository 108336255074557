import React, { useContext, useEffect, useState } from  'react'
import ServicesType from '../services-type/ServicesType'
import UserData from '../user-data/UserData'
import PayMethods from '../pay-methods/PayMethods'
import Checkout from '../checkout/Checkout'
import Stripe from '../stripe/Stripe'
import Binance from '../binance/Binance'
import Paypal from '../paypal/Paypal'
import Success from '../success/Success'
import ReportPayment from '../report-payment/ReportPayment'
import AppContext from '../../context/AppContext'
import { useTransition, animated } from 'react-spring'
import Loader from '../../helpers/Loader'
import { splitFullName } from '../../helpers/Helpers'
import { AlertError, AlertSuccess } from '../../helpers/SweetAlert'
import { services } from '../../helpers/Services'
import Logo from '../../assets/logo.svg'


const Modal = () => {

    const {

        user, // user information
        setUser, // user data setter
        payMethod, // pay method info
        setPayMethod, // pay method setter
        payMethods, // list of payment methods
        service,  // service data
        setService, // service setter
        step, // step
        setStep, // step setter
        fromApp, // boolean to allow user to select services and user (false for allow)
        setFromApp, // fromApp boolean setter
        getRidesUserData, // func to get rides user
        getDeliveryUserData, // func to get delivery user
        binanceWebhook, // func to check binance payment
        checkPaypalPayment // func to check paypal payment
    } = useContext(AppContext)
    
        // query string getting from url
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    /**
     * react states
     */
    const [ amount, setAmount ] = useState('') // amount for stripe
    const [ loadingCheck, setLoadingCheck ] = useState(true) // loader that is showing when check payment func is exec
    const [ timer, setTimer ] = useState(false) // timer for show message

    
    /**
     * React spring transitions
     * @param {int} step 
     * @returns React component
     */
    const transition = useTransition(step, {
        from: { x: -50, opacity: 0, height: '100%', width: '100%' },
        enter: { x: 0, opacity: 1, height: '100%', width: '100%' },
        // leave: { x: 50, opacity: 0 }
    })
    
    // effect to call checcPayment and get user functions
    useEffect(()=>{
        checkPayment()
        getUser()
        setTimeout(()=>{
            setTimer(true)
        }, 10000)
    },[])

    /**
     * func to get user info when they come from apps, it take data from url params y send to pay methods view
     * user isnt allowed to select diferent service or search another user information
     */
    const getUser = async () => {
        const service = urlParams.get('service');
        const phoneNumber = urlParams.get('phoneNumber');
        let data;
        if(service && phoneNumber){
            setLoadingCheck(true)
            if(service === "RIDES"){
                setService(services[1])
                data = await getRidesUserData(phoneNumber)
                if(data){
                    setLoadingCheck(false)
                    const initial_lastname = data.user.last_name.charAt(0)
                    const userData = {...data.user, initial_lastname, formattedPhone: `+58${data.user.phone}`  }
                    setUser(userData)
                    window.localStorage.setItem("userData", JSON.stringify({
                        service: services[1],
                        user: userData,
                        fromApp: true
                    }))
                }
            }else {
                setService(services[0])
                data = await getDeliveryUserData(phoneNumber)
                if(data){
                    setLoadingCheck(false)
                    const splitName = splitFullName(data.data.name)
                    const initial_lastname = splitName[1].charAt(0)
                    const userData = {...data.data, first_name: splitName[0], initial_lastname, formattedPhone: data.data.phone}
                    setUser(userData)
                    window.localStorage.setItem("userData", JSON.stringify({
                        service: services[0],
                        user: userData,
                        fromApp: true
                    }))
                }
            }
            setStep(2)
            setFromApp(true)
        }
    }
    

    /**
     * func for checking diferent types of payments
     * it have 3 conditions by URL params:
     * 
     * **success** and **cancel** are for Stripe
     * 
     * **token** is for Paypal
     */
    const checkPayment = async () => {
        const token = urlParams.get('token');
        const success = JSON.parse(urlParams.get('success'));
        const cancel = JSON.parse(urlParams.get('cancel'));
        const amount = urlParams.get('amount');
        const userData = window.localStorage.getItem('userData')
        const parsedData = JSON.parse(userData)
        setAmount(amount)
        setLoadingCheck(true)
        
        // begin Stripe logic
        if(success){
            isSuccessStripe()
        }
        if(cancel) {
            wasCanceledStripe(cancel, userData, parsedData)
           return
       }
        // end Stripe logic


        // begin Paypal logic 
        if(token){
            const res = await checkPaypalPayment(token)
            if(!res){
                AlertError(`Ocurrió un error, por favor comunicate con nuestro soporte`)
                setLoadingCheck(false)
                return
            }
            isCheckedPaypal(res, parsedData)
        }
        // end Paypal logic 
        setLoadingCheck(false)
    }

    /**
     * func calls when paypal is processing
     * @param {*} res 
     * @param {*} parsedData 
     * @returns 
     */
    const isCheckedPaypal = (res, parsedData) => {
        if(res.data && res.data.success){
            if(parsedData.fromApp){
                setFromApp(true)
                setService(parsedData.service)
                setUser(parsedData.user)
                window.localStorage.removeItem('localData')
            }else {
                window.localStorage.clear()
            }
            setLoadingCheck(false)
            window.history.replaceState({}, document.title, "/");
            setStep(5)
            return
        }
        if(res.status === 500){
            setTimeout(()=>{
                checkPayment()
            }, 3000)
            return
        }
        AlertError(`Ocurrió un error, por favor comunicate con nuestro soporte`)
        setLoadingCheck(false)
    }

    /**
     * func is calling when user cancel on stripe checkout, it check the local storage for manage user data
     * @param {*} cancel 
     * @param {*} userData 
     * @param {*} parsedData 
     */
    const wasCanceledStripe = (cancel, userData, parsedData) => {
        if(cancel){
            if(userData){
                setLoadingCheck(false)
                setService(parsedData.service)
                setUser(parsedData.user)
                if(parsedData.fromApp){
                    setFromApp(true)
                }
                setStep(2)
                window.localStorage.removeItem('localData')
            }
        }else {
            setLoadingCheck(false)
            window.localStorage.clear()
        }
    }

    /**
     * func is calling when stripe payment is successful, just go to success step (5) and clear local storage
     */
    const isSuccessStripe = () => {
        setStep(5)
        window.localStorage.clear()
    }
    
    /**
     * effect for set the color, it depends of service selected
     */
    const [ color, setColor ] = useState('')
    useEffect(()=>{
        const color = service ? service.bgColor : null
        setColor(color)
    },[service])
    
// borrar comentario  1
    return (
        <div className='modal shadow'>
            <div style={{ margin: 'auto', textAlign: 'center' }}>
                <img style={{ width: '70px', marginBottom: 20 }} src={Logo} alt="yummy logo" />
                <p>Servicio no disponible 😔</p>
                <p>¡Estaremos de vuelta pronto!</p>
            </div>
            {/* {
                loadingCheck
                ?
                <>
                    <div className='loader-container' style={{ margin: 'auto' }}>
                        <Loader service={service.name} />
                    </div>
                    {
                        timer && <p className='long-time'>Parece estar tardando mas de lo habitual, no te preocupes, trabajamos en ello! ⚙️🔨</p>
                    }
                </>
                :
                <>
                    {   
                        transition((style, item)=>
                            item === 0 && 
                            <animated.div style={style}>
                                <ServicesType setService={setService} setStep={setStep} />
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 1 && 
                            <animated.div style={style}>
                                <UserData setUser={setUser} user={user} service={service} setStep={setStep} color={color} />  
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 2 && 
                            <animated.div style={style}>
                                <PayMethods setPayMethod={setPayMethod} setStep={setStep} fromApp={fromApp} service={service} payMethods={payMethods} />
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 3 && 
                            <animated.div style={style}>
                                <Checkout payMethod={payMethod} service={service} setStep={setStep} user={user} color={color} />
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 4 && 
                            <animated.div style={style}>
                                <Stripe setStep={setStep} user={user} color={color} service={service} />
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                        item === 5 && 
                        <animated.div style={style}>
                                <Success setStep={setStep} amount={amount} fromApp={fromApp} />
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                        item === 6 && 
                        <animated.div style={style}>
                                <ReportPayment setStep={setStep} service={service} user={user} color={color} />
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 7 && 
                            <animated.div style={style}>
                                <Binance setStep={setStep} user={user} color={color} service={service} binanceWebhook={binanceWebhook} />
                            </animated.div> 
                        )
                    }
                    {   
                        transition((style, item)=>
                            item === 8 && 
                            <animated.div style={style}>
                                <Paypal setStep={setStep} user={user} color={color} service={service} />
                            </animated.div> 
                        )
                    }
                </>
            } */}
        </div>
    )
}

export default Modal
import React, { useState, useEffect } from "react";
import AppContext from "./AppContext";
import axios from 'axios'
import { getPayMethods } from '../helpers/PayMethods'
import { getDolarRate } from '../helpers/DolarRate'
import { AlertError } from '../helpers/SweetAlert'

const AppState = ({ children }) => {
  
    /**
     * states and setters for input events and styles
     */
    const [user, setUser] = useState(false)
    const [payMethod, setPayMethod] = useState(false)
    const [rate, setRate] = useState(false)
    const [service, setService] = useState(false)
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [fromApp, setFromApp] = useState(false)
    const [payMethods, setPayMethods] = useState([])

    /**
     * funcs that call the paymethods func
     */
    useEffect(()=>{
      handlePayMethods()
      handleDolarRate()
    },[])
    /**
     * func that fetch all pay methods and set on state
     */
    const handlePayMethods = async () => {
      const data = await getPayMethods()
      if (data) {
        setPayMethods(data)
      }
  }
    /**
     * func that fetch dolar rate
     */
    const handleDolarRate = async () => {
      const data = await getDolarRate()
      if (data) {
        data.data.success && setRate(data.data.rate)
      }
  }

  /**
   * fetch user data from rides
   * @param {number} phone 
   * @returns 
   */
    const getRidesUserData = async (phone) => {
      setLoading(true)
      let path = 'get_user_info'  
      const res = await axios.get(`${process.env.REACT_APP_API_RIDES_URL}/${path}?phone=${phone}`, 
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${process.env.REACT_APP_API_KEY_RIDES}`
        }
      })
      .then(res => {
        setLoading(false)
        console.log(res)
        return res.data
      }).catch(err => {
        console.error(err)
        return {
          data: []
        }
      })
  
      return res
    }

    /**
     * fetch user data from delivery
     * @param {number} phone 
     * @returns 
     */
    const getDeliveryUserData = async (phone) => {
      setLoading(true)
      try{
        let path = 'api/payments/delivery/user'  
        const res = await axios.get(`${process.env.REACT_APP_RECHARGE_URL}/${path}/+${phone}`, 
        {
          headers: {
            "Content-Type": "application/json",
            "x-authorization": `Bearer ${process.env.REACT_APP_RECHARGE_BEARER}`
          }
        })
        setLoading(false)
        return res
      }catch(e){
        console.log(e)
        setLoading(false)
        return []
      } 
    }

    /**
     * report payment from pago movil
     * @param {object} data 
     * @returns status
     */
    const reportMobilePayment = async (data) => {
      try{
        let path = 'pago-movil/verify'
        const headers = { 
          "Content-Type": "application/json",
          "x-api-key": `${process.env.REACT_APP_API_KEY_PAYMENTS}`
        };
        const res = await axios.post(`${process.env.REACT_APP_API_PAYMENTS_URL}/${path}`, data, { headers })
        return res
      }catch (e){
        console.log(e)
      }
    }

    /**
     * func to check payment webhook process
     * @param {object} data 
     * @param {object} headers 
     * @param {func} setLoading 
     */
    const binanceWebhook = async (data, headers, setLoading) => {
      // console.log(user)
      try{
          const body = {
              "payment_method": "BINANCE",
              "yummy_service": data.serviceName,
              "amount": data.amount,
              "phone": user.formattedPhone.slice(1),
              "payment_id": data.prepayId,
              "from": `${user.first_name} ${user.last_name}`,
              "origin_app": "credits"
          }
          const webhook = await axios.post(`${process.env.REACT_APP_API_PAYMENTS_URL_PROD}/binance/payments/insert`, body, { headers })
          // console.log('webhook', webhook)
          if(webhook.data.success){
              // window.open(data.universalUrl, '_blank')
              setLoading(false)
              // setStep(0)
              return data.universalUrl
          }else {
              AlertError('Ocurrio un error, intentalo de nuevo mas tarde')
              setLoading(false)
            }
          }catch(e) {
            AlertError('Ocurrio un error, intentalo de nuevo mas tarde')
            setLoading(false)
            console.log(e)
      }
  }
  
  /**
   * func to check paypal webhook process
   * @param {string} id 
   * @returns 
   */
  const checkPaypalPayment = async (id) => {
    try {
      const path = 'paypal/order/capture'
      const res = await axios.post(`${process.env.REACT_APP_API_PAYMENTS_URL}/${path}/${id}`, {}, {
        headers: { 
          "Content-Type": "application/json",
          "x-api-key": `${process.env.REACT_APP_API_KEY_PAYMENTS}`
        }
      })
      console.log(res)
      return res
    }catch(e){
      console.log(e)
      return { status: 500 }
    }
  }

  return (
    <AppContext.Provider
      value={{
        user, setUser,
        payMethod, setPayMethod,
        rate,
        service, setService,
        step, setStep,
        loading, setLoading,
        fromApp, setFromApp,
        payMethods, setPayMethods,
        getRidesUserData,
        getDeliveryUserData,
        reportMobilePayment,
        binanceWebhook,
        checkPaypalPayment
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppState;

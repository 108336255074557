import Swal from 'sweetalert2'

export const AlertError = (message) => {
    Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        heightAuto: false
    })
}

export const AlertSuccess = (message) => {
    Swal.fire({
        icon: 'success',
        title: 'Listo!',
        text: message,
        heightAuto: false
    })
}

/**
 * split name when first name and last name are inside one string
 * @param {*} full_name 
 * @returns 
 */
export const splitFullName = (full_name) => {
    // console.log(full_name)
    const splitName = full_name.split(' ')
    if(splitName.length < 2) {
        return [full_name, ""]
    }
    return splitName
}

/**
 * randon string generator
 * @param {number} length 
 * @param {string} chars 
 * @returns 
 */
export const randomString = (length, chars) => {
    var mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    var result = '';
    for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
    return result;
}
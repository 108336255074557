import React from 'react'
import Modal from './components/home/Modal'
import AppState from './context/AppState';
import { Toaster } from 'react-hot-toast'


function App() {
  return (
    <AppState>
      <Toaster />
      <Modal />
    </AppState>
  );
}

export default App;
